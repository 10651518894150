.ForSuppliersPage {
    &-Container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tablet {
            flex-direction: row;
        }

        > div {

            h2 {
                font-family: "Montserrat-900";
                text-transform: uppercase;
                text-align: left;
                
                font-size: 24px;

                @include tablet {
                    font-size: 30px;
                }
            }

            &:last-of-type {
                border-top: 2px solid $color-light4;

                @include tablet {
                    border-top: none;
                    min-width: 260px;
                }
            }
        }


    }
    
    
    &-BuyIskButton {
        border-bottom: 2px solid $color-brand-dark;
        display: block;
        font-weight: bold;
        line-height: 30px;
        margin: -10px auto 20px;
        max-width: 350px;
        padding: 10px 20px;
        text-align: center;
        transition: transform .3s;
        width: inherit;
    
        &:hover {
            transform: scale(1.02);
        }
    }

    &-WhyList {
        list-style-type: circle;
        margin-left: 20px;
    }
}
