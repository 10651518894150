[data-tooltip] {
    cursor: help;

    &::after {
        background: rgba($color-brand-dark, .9);
        border-radius: 10px;
        box-shadow: 0 0 0 0.2rem rgba($color-brand-red, .3);
        color: #fff;
        content: attr(data-tooltip);
        opacity: 0;
        margin: -3px 0 0 20px;
        padding: 3px 15px;
        position: absolute;
        transition: all .15s ease;
        visibility: hidden;
    }
    
    &:hover,
    &:focus {
        &::after {
            opacity: .85;
            visibility: visible;
        }
    }
}
