.ProductBlock {
    &-Items {
        .CategoryPage-Container & {
        display: grid;
            gap: 16px;
            grid-template-columns: 1fr 1fr;
            width: 100%;

            @include mobile-big {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include desktop {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }

    &-Item {
        &:hover {
            .ProductBlock-Item__Image {
                @include desktop {
                    transform: scale(1.1);
                }
            }

            .ProductBlock-Item__Title {
                @include desktop {
                    color: $color-brand-red;
                }
            }
        }

        &__Image {
            background-position: left;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            transition: transform .3s ease-in-out;
            transform-origin: center;
            width: 100%;

            &Container {
                border-radius: 4px;
                margin-bottom: 5px;
                display: block;
                height: 207px;
                overflow: hidden;
                position: relative;
            }
        }

        &__Title {
            color: $color-brand-dark;
            display: block;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            padding-bottom: 5px;
            text-align: center;
            transition: color .3s ease-in-out;
        }
    }
}
