.about-us-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;

    @include tablet {
        flex-direction: row;
        align-items: flex-start;
    }     

    &-left {
        flex-direction: column;

        h2 {
            font-family: "Montserrat-900";
            text-transform: uppercase;
            text-align: center;

            @include tablet {
                text-align: left;
            }   
        }

        p {
            text-align: justify;
            font-family: "Play-Regular";
        }
        
    }

    &-right {
        position: relative;        
        margin: 10px auto 30px auto;
        width: 300px;
        height: 300px; 

        @include tablet {
            width: 400px;
            height: 400px;
            margin: 40px 0 40px 40px;  
        }      
    
        &::after {
            background-color: #1d2226;
            content: "";
            display: block;
            height: 110%;
            width: 110%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 0;
            border-radius: 10px;  
        }
    
        img {
            height: 100%;
            width: auto;
            position: relative;
            z-index: 1;  
        }        
    }
}