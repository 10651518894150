.Footer {
    background-color: $color-brand-dark;
    color: $color-light4;
    font-weight: 300;
    text-align: center;
    border-top: 2px solid #ff5b57;

    a {
        color: $color-light4;
    }

    p {
        width: 100%;
    }

    .Container {
        padding-top: 30px;
        padding-bottom: 15px;

        @include tablet() {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 30px;
        }
    }

    &-Left {

        @include tablet {
            width: 30%;
        }
    }

    &-Right {
        margin-top: 30px;

        @include tablet {
            margin-top: 0;
            padding-left: 15px;
            width: 70%;
        }

        p {
            text-align: center;

            @include tablet {
                text-align: right;
            }
        }
    }

    &-MenuTitle {
        display: none;
        font-family: 'Montserrat-700';
        text-transform: uppercase;

        @include tablet {
            margin-bottom: 10px;
            font-size: 22px;
            display: inline-block;
        }
    }

    &-Menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 6px;
        
        @include tablet {
            margin-top: 10px;
            flex-direction: column;
        }

        > * {
            padding: 6px 7px;
            color: white;
            font-family: "Play-Regular";
            text-transform: lowercase;
            text-align: center;
            white-space: nowrap;
            text-decoration: underline;
            text-underline-offset: 5px;
            margin: 3px 10px;

            @include tablet {
                padding: 6px 10px;
            }

            &:hover {
                color: orange;                   
            }
        }
    }

    &-PaymentIcons {
        display: flex;
        gap: 14px;
        justify-content: center;
        margin-bottom: 20px;

        @include tablet {
            justify-content: right;
        }

        img {
            height: 30px;
        }
    }

    &-SocialIcons {
        display: flex;
        gap: 14px;
        justify-content: center;
        margin-bottom: 0;

        @include tablet {
            justify-content: right;
            margin-bottom: 5px;
        }

        a {
            background-color: $color-brand-light;
            border-radius: 3px;
            color: #fff;
            display: block;
            height: 36px;
            margin-bottom: 15px;
            position: relative;
            text-align: center;
            width: 36px;

            @include tablet {
                height: 50px;
                width: 50px;

            }

            &::before {
                background: #fff;
                content: '';
                mask-size: cover;
                height: 16px;
                left: 10px;
                position: absolute;
                top: 10px;
                width: 16px;

                @include tablet {
                    height: 24px;
                    left: 13px;
                    top: 13px;
                    width: 24px;
                }
            }

            &.mail-bg {
                &::before {
                    mask-image: url('../../../images/icons/envelope.svg');
                }

                &:hover,
                &:focus {
                    background-color: #ff5d5d;
                }
            }

            &.skype-bg {
                &::before {
                    mask-image: url('../../../images/icons/skype.svg');
                }

                &:hover,
                &:focus {
                    background-color: #00aff0;
                }
            }

            &.discord-bg {
                &::before {
                    mask-image: url('../../../images/icons/discord.svg');
                }

                &:hover,
                &:focus {
                    background-color: #7289da;
                }
            }

            &.twitter-bg {
                &::before {
                    mask-image: url('../../../images/icons/twitter.svg');
                }

                &:hover,
                &:focus {
                    background-color: #1da1f2;
                }
            }

            &.youtube-bg {
                &::before {
                    mask-image: url('../../../images/icons/youtube.svg');
                }

                &:hover,
                &:focus {
                    background-color: #ff0000;
                }
            }
        }
    }

    &-Trustpilot {
        margin-bottom: 20px;
        opacity: .6;
        
        > iframe {
            @include tablet {
                margin-left: auto !important;
                transform: translateX(30px);
                width: auto !important;
            }
        }
    }
}

.footer-nav > li {
    margin-bottom: 10px;
}


