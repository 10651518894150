.CategoryPage {
    &-Container {
        align-items: stretch;
        display: flex;
        gap: 8px;
        flex-direction: column;
        flex-wrap: wrap;

        @include tablet {
            flex-direction: row;
            gap: 16px;
        }

        .Pagination {
            grid-column: 1/3;

            @include mobile-big {
                grid-column: 1/4;
            }

            @include desktop {
                grid-column: 1/5;
            }
        }
    }

    &-Hero {
        margin: -20px 0 30px;
        min-height: 200px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        @include tablet {
            min-height: 300px;
        }

        .Container {
            position: unset;
        }

        &Image {
            background-position: center;
            background-size: cover;
            filter: blur(2px);
            height: calc(100% + 20px);
            left: 0;
            margin: -10px;
            position: absolute;
            top: 0;
            width: calc(100% + 20px);
            z-index: -1;
        }

        &Title {
            margin: 30px 0;
            font-family: 'Montserrat-900';
            // background: rgba(0,0,0,0.6);
            // padding: 5px 10px;
            // border-radius: 10px;;
        }

        &Description {
            margin-bottom: 30px;
        }

        &Title,
        &Description {
            color: #fff;
            margin-right: auto;
            max-width: 500px;
            text-align: left;
        }
    }

    &-CharacterBlock {
        background: $color-brand-dark;
        border-bottom: 2px solid $color-brand-red;
        border-radius: 4px;
        color: #fff;
        padding: 15px;
        position: relative;
        text-align: center;
        width: 100%;

        @include tablet {
            max-width: calc(100% / 3 - 11px);
            padding: 15px 15px 60px;
        }

        > a {
            color: #fff;
            display: block;
        }

        h2 {
            margin: -10px 0 0 0;
        }

        &__Image {
            background-size: cover;
            background-position: center;
            height: 220px;
            margin: 5px -15px 15px;
            width: calc(100% + 30px);
        }

        &Vertical {
            background: none;
            border-bottom: none;
            display: flex;
            gap: 8px;
            flex-direction: column;
            padding: 0;

            @include tablet {
                gap: 16px;
            }

            > * {
                background: $color-brand-dark;
                border-bottom: 2px solid $color-brand-red;
                border-radius: 4px;
                color: white;
                height: 100%;
                padding: 15px;
                position: relative;
                text-align: center;
                width: 100%;

                @include tablet {
                    padding: 15px 15px 60px;
                }
            }
        }

        .Btn {
            text-transform: uppercase;
            width: 100%;

            @include tablet {
                bottom: 15px;
                left: 15px;
                position: absolute;
                width: calc(100% - 30px);
            }
        }
    }

    &-EmptyMessage {
        font-size: 24px;
        text-align: center;
        width: 100%;
    }
    
    &-StructureFilters {
        border-bottom: 2px solid $color-light2;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: center;
        margin-bottom: 30px;
        padding-bottom: 20px;
        width: 100%;

        &__Title {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            width: 100%;
            font-family: "Montserrat-700";
            text-transform: uppercase;
        }
    }

    &-StructureFilter {
        background-color: $color-brand-dark;
        border: none;
        flex: 1;
        font-family: "Montserrat-700";
        text-transform: uppercase;

        &__Active {
            background-color: $color-brand-red;
        }
    }
}

.ship-type-filter {
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 50px;
    margin: 0 4px;
    position: relative;
    width: 40px;

    &.active::before {
        border: 2px solid $color-brand-red;
        content: "";
        display: block;
        height: 50px;
        left: 0;
        position: absolute;
        top: 0;
        width: 40px;
    }
}
