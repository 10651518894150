.FaqPage {
    &-Container {
        max-width: 800px;

        ol {
            list-style: none;
            margin-top: 15px;
            padding-left: 0;
        }

        > ol {
            li {
                counter-increment: increment1;
                margin-bottom: 15px;
                padding-left: 50px;
                position: relative;

                &::before {
                    content: counter(increment1);
                    display: inline-block;
                    font-size: 16px;
                    left: 0;
                    line-height: 38px;
                    position: absolute;
                    background: $color-brand-red;
                    color: white;
                    text-align: center;
                    border-radius: 50%;
                    width: 38px;
                    margin-right: 20px;
                }

                ol {
                    li {
                        counter-increment: increment2;
                        margin-bottom: 5px;
                        padding-left: 40px;

                        &::before {
                            background: $color-brand-dark;
                            content: counter(increment2, lower-latin);
                            font-size: 14px;
                            line-height: 29px;
                            width: 29px;
                        }
                    }
                }
            }

            .card-body {
                @include tablet() {
                    padding-left: 15px;
                }
            }
        }
    }
    
    &-FaqTitle {
        cursor: pointer;
        display: inline-block;
        margin-top: 0;
    }

    &-Collapse {
        display: none;
    }
}
