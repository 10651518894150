.ProductPage {
    &-TopBackground {
        height: 200px;
        margin: -10px;
        left: -10px;
        opacity: .6;
        overflow: hidden;
        position: absolute;
        top: 147px;
        width: calc(100% + 20px);

        @include tablet {
            height: 270px;
        }

        @include desktop {
            height: 152px;
        }

        div {
            &::before {
                content: '';
                background-color: $color-brand-dark;
                background-image: url($pattern-red);
                background-position-y: -10px;
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tablet {
            flex-direction: row;
        }

        >div {
            z-index: 1;

            @include tablet {
                width: 50%;
            }
        }
    }

    &-Title {
        font-weight: bold;
        text-align: center;
        font-family: 'Montserrat-900';
        text-transform: uppercase;

        @include desktop {
            font-size: 50px;
        }
    }

    &-Image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 4px;
        min-height: 200px;
        width: 100%;
    }

    &-Info {
        background: $color-brand-dark;
        color: white;
        margin: 0 -15px;
        padding: 15px;

        @include tablet {
            border-radius: 4px;
            margin: 0;
        }

        .trustpilot-widget {
            opacity: .6;
            transition: opacity .2s;

            &:hover {
                opacity: 1;
            }

            iframe {
                margin-left: auto;
                transform: translateX(32px);
            }
        }
    }

    &-BuyContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: space-between;
        margin: 30px 0;

        @include desktop {
            align-items: center;
            flex-direction: row;
        }

        >div {
            padding: 0;
        }
    }

    &-DiscountDiff {
        display: none;
    }

    &-AddToCart {
        font-size: 16px;
        font-weight: 600;
        line-height: 53px;
        text-transform: uppercase;
        width: 100%;

        @include desktop {
            width: 200px;
        }

        &::before {
            background: url('../../../images/icons/shopping-cart.svg') no-repeat 50%;
            background-size: contain;
            content: '';
            display: inline-block;
            height: 20px;
            padding-right: 8px;
            position: relative;
            top: 3px;
            width: 20px;
        }
    }

    &-Price {
        &Container {
            font-size: 26px;
        }

        &__Discount {
            display: none;
        }
    }

    &-ShowDiscount {
        .ProductPage-Price {
            color: #9e9e9e;
            text-decoration: line-through;

            &__Discount {
                display: inline-block;
            }
        }

        .ProductPage-DiscountDiff {
            color: #ffd0d0;
            display: block;
            font-size: 15px;
            margin-top: -5px;
        }
    }

    &-OmegaClone {
        margin: 30px 0 -20px;

        label {
            color: white;
        }

        >div {
            padding: 0;
        }

        &__Or {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            &::before,
            &::after {
                background: #ffffff;
                content: '';
                display: block;
                height: 1px;
                flex: 1;
                opacity: .2;
            }

            span {
                flex-shrink: 0;
                opacity: .6;
                padding: 0 14px;
            }
        }

        &__Form {
            align-items: baseline;
            display: none;
            justify-content: space-between;
            margin-top: 35px;

            label {
                color: white;
                display: inherit;
            }
        }

        &__Input {
            border: 0;
            color: $color-brand-red;
            background: $color-brand-light;
            float: right;
            width: 70px;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                opacity: 1;
            }
        }
    }

    &-Qty {
        &Range {
            min-height: 50px;
            opacity: 0;
            visibility: hidden;
        }

        &Preselector {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: space-around;
            margin-top: 20px;

            >div {
                color: #fff;
                gap: 8px;
                flex: 1;
            }
        }
    }

    &-Description {
        padding-top: 10px;

        @include tablet {
            padding-top: 15px;
        }
    }

    &-AdditionalInfo,
    &-DeliveryTerms {
        background: $color-light2;
        border-radius: 4px;
        margin-top: 15px;
        padding: 15px;
    }

    &-Delivery {
        margin-top: 25px;

        &Method {
            background: $color-brand-light;
            border-radius: 4px;
            cursor: pointer;
            margin-bottom: 7px;
            padding: 7px 15px;

            div {
                font-size: 15px;
                position: relative;

                &::before {
                    background: white;
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    height: 16px;
                    margin-right: 10px;
                    transform: translateY(2px);
                    width: 16px;
                }
            }

            p {
                display: none;
                margin: 20px 0 0 28px;
            }

            &__Active {
                div {
                    &::after {
                        background: $color-brand-red;
                        border-radius: 50%;
                        content: '';
                        height: 10px;
                        left: 3px;
                        position: absolute;
                        top: 6.5px;
                        width: 10px;
                    }
                }

                p {
                    display: block;
                }
            }
        }
    }

    &-ShipFactions {
        display: flex;
        gap: 8px;
        justify-content: space-between;
    }

    &-ShipFaction {
        cursor: pointer;
        padding: 5px;
        position: relative;

        @include tablet {
            padding: 10px;
        }

        > div {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 50px;
            margin: 0 auto;
            position: relative;
            width: 40px;
        }

        &__Active {
            &::before {
                border: 2px solid $color-brand-red;
                border-radius: 4px;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    &-Related {
        &Items {
            margin-top: 30px;

            .slick-slide {
                margin: 0 15px;
            }

            .slick-list {
                margin: 0 -15px;
            }

            &Title {
                align-items: center;
                display: flex;

                > * {
                    width: 100%;
                }
            }
        }

        &Navigation {
            display: flex;
            flex-direction: row;
            gap: 16px;
            justify-content: flex-end;
            padding-right: 0;
            width: auto;

            @include media(1500px, min-width) {
                display: none;
            }

            > div {
                background: $color-brand-red;
                border-radius: 3px;
                color: #ffffff;
                cursor: pointer;
                font-size: 20px;
                font-weight: bold;
                padding: 0px 12px 4px;
                transition: background 0.2s;
                line-height: 30px;

                &:hover {
                    background: darken($color-brand-red, 10%);
                }
            }
        }
    }
}
