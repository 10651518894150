.CheckoutPage {
    $checkout-content-max-width: 628px;
    $sidebar-width: 250px;
    $sidebar-gap: 32px;

    &-Container {
        display: flex;
        gap: 16px;
        flex-direction: column;
        max-width: 910px;

        @include tablet {
            flex-wrap: wrap;
            flex-direction: row;
            gap: $sidebar-gap;
        }

        &__Success {
            justify-content: center;
            text-align: center;

            h2,
            h3 {
                text-transform: uppercase;
            }

            p {
                margin-bottom: 8px;
            }

            strong {
                color: $color-brand-red;
            }

            .trustpilot-widget {
                margin-top: 30px;
            }
        }
    }

    &-Navigation {
        display: flex;
        flex: 100%;
        gap: 8px;
        justify-content: center;
        padding-top: 20px;

        &Step {
            color: $color-light1;
            padding: 0 10px;
            text-align: center;
            width: 90px;

            @include tablet {
                padding: 0 30px;
                width: 180px;
            }

            &:after {
                background-color: $color-light1;
                border-radius: 1px;
                bottom: 28px;
                content: '';
                display: block;
                height: 2px;
                left: 46px;
                margin: 0 auto;
                position: relative;
                width: 40px;

                @include tablet() {
                    bottom: 49px;
                    left: 92px;
                    width: 100px;
                }
            }

            &:last-child:after {
                display: none;
            }

            &__Number {
                background: $color-light1;
                border-radius: 50%;
                color: white;
                display: block;
                height: 35px;
                line-height: 35px;
                margin: 0 auto 10px;
                width: 35px;
            }

            &__Title {
                display: none;

                @include tablet {
                    display: block;
                }
            }

            &__Active {
                color: $color-brand-red;

                .CheckoutPage-NavigationStep__Number {
                    background: $color-brand-red;
                }
            }

            &__Complete {
                color: $color-brand-red;

                .CheckoutPage-NavigationStep__Number,
                &:after {
                    background-color: $color-brand-red;
                }
            }
        }
    }

    &-Content {
        flex: 1 0 auto;
        margin: 0 auto;
        max-width: $checkout-content-max-width;
        order: 4;
        width: 100%;

        @include tablet {
            order: unset;
            width: calc(100% - #{$sidebar-width} - #{$sidebar-gap});
        }

        .Btn-Group {
            justify-content: flex-end;

            .Btn {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &-Sidebar {
        border-bottom: 2px solid $color-light4;
        margin: 15px auto 0 auto;
        max-width: $checkout-content-max-width;
        padding-bottom: 35px;
        width: 100%;

        @include tablet {
            border: none;
            margin: 0;
            padding: 0;
            width: $sidebar-width;
        }
    }

    &-ItemsTable {
        tr {
            &:not(:last-of-type) {
                border-bottom: 1px solid $color-light4;
            }

            td {
                @include tablet {
                font-size: 16px;
                line-height: 2;
                }
            }
        }

        th,
        td {
            &:first-of-type {
                text-align: left;
            }
        }
    
        &__Qty {
            span {
                display: none;
            }
        }

        &__Title {
            color: $color-brand-red;
            min-width: 35%;

            .product-title {
                font-family: 'Montserrat-700';
                small {
                    font-family: "Play-Regular";
                }
            }
        }
    
        &__Price {
            text-align: center;
            width: calc(100% - 200px);
        }
    
        &__Remove {
            width: 52px;
        }

        &__Delete {
            &::before {
                background: $color-brand-dark;
                content: '';
                cursor: pointer;
                display: block;
                height: 16px;
                margin-left: 8px;
                mask-size: contain;
                mask-image: url(../../../../images/icons/trash.svg);
                transform: translateY(1px);
                width: 16px;
            }
        }
    }

    &-TotalTable {
        margin-bottom: 15px;

        td {
            &:first-of-type {
                padding-left: 0;
                text-align: left;
            }
            
            &:last-of-type {
                padding-right: 0;
                text-align: right;
            }
        }

        tr:last-of-type {
            td {
                border-top: 2px solid $color-light4;
                font-size: 120%;
            }
        }
    }


    &-Discount {
        &Label {
            font-size: 16px;
            margin-bottom: 8px;
        }

        &Row {
            color: $color-brand-red;
        }

        &Label {
            &Cancel {
                display: none;
            }
        }

        &InputGroup {
            &__Invalid {
                input {
                    border-color: $color-brand-red;
                }
            }
        }
    }

    &-Coupon {
        &Exist {
            .CheckoutPage-DiscountLabel {
                &Apply {
                    display: none;
                }

                &Cancel {
                    display: block;
                }
            }
        }
    }

    &-PaymentMethod {
        .Accordion-Content {
            min-height: 90px;
        }
    }

    &-Pay {
        display: flex;
        gap: 11px;
        justify-content: center;
        flex-direction: column;
        padding-top: 20px;

        .Btn-PayOffline {
            margin-bottom: 20px;
        }
    }

    &-Paypal {
        margin-bottom: -20px;
    }

    &-Stripe {
        &Group {
            @extend input;
            margin-bottom: 0;

            &__Expiry {
                display: flex;
                gap: 16px;
            }

            &.StripeElement--focus {
                border-color: $color-brand-red;
                box-shadow: 0 0 0 0.25rem rgba($color-brand-red, .25);
            }
        }

        &Copyright {
            color: $color-dark3;
            display: block;
            font-size: 10px;
            text-decoration: underline;
            padding-top: 5px;
            text-align: center;
        }

        &Error {
            color: $color-brand-red;
        }
    }

    &-SuccessIcon {
        background: $color-brand-red;
        display: block;
        height: 80px;
        mask-image: url(../../../../images/icons/handshake.svg);
        mask-size: contain;
        margin: 50px auto 0;
        width: 80px;
    }

    &-EmptyCart {
        font-size: 20px;
        padding-top: 40px;
        text-align: center;

        @include tablet {
            font-size: 30px;
        }
    }
}
