.Checkbox {
    display: block;

    input[type=checkbox] {
        display: none;

        + span {
            cursor: pointer;
            display: inline-block;
            font-size: 13px;
            margin-right: 10px;
            position: relative;
            vertical-align: middle;

            &::before {
                border: 1px solid $color-light1;
                border-radius: 4px;
                bottom: 1px;
                content: '';
                display: inline-block;
                height: 18px;
                margin-right: 8px;
                position: relative;
                vertical-align: middle;
                width: 18px;
            }
        }

        &:checked + span {
            &::before {
                background: $color-brand-red;
                border: 1px solid $color-brand-red;
                height: 18px;
                width: 18px;
            }

            &::after {
                background: white;
                content: '';
                display: block;
                height: 14px;
                left: 3px;
                mask-image: url('../../../images/icons/check.svg');
                mask-size: contain;
                margin: 0 auto;
                position: absolute;
                top: 3px;
                width: 14px;
            }
        }
    }

    &.Checkbox-Dark {
        input[type=checkbox] {        
            + span {
                &::before {
                    border: 1px solid $color-dark1;
                }
            }
        }
    }

    &.parsley-error {
        span {
            &::before {
                border: 2px solid $color-red !important;
            }
        }
    }

    &:focus input[type=checkbox] + span {
        &::before {
            border-color: $color-brand-red;
            box-shadow: 0 0 0 0.25rem rgba($color-brand-red, .25);
        }
    }
}
