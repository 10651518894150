.slick {
    &-slider {
        display: block;
        position: relative;
        touch-action: pan-y;
        user-select: none;
    
        .slick-list {
            transform: translate3d(0, 0, 0);
        }
    
        .slick-track {
            transform: translate3d(0, 0, 0);
        }
    }
    
    &-list {
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
    
        &:focus {
            outline: 0;
        }
    
        &.dragging {
            cursor: hand;
        }
    }

    &-track {
        display: flex;
    }
}

.slick-dots {
    bottom: 0;
    display: block;
    list-style: none;
    margin-top: 15px;
    text-align: center;
    padding: 0;
    position: relative;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 12px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 12px;

        button {
            border: 0;
            color: transparent;
            cursor: pointer;
        }
    }
}
