input,
textarea {
    border: 2px solid $color-light1;
    border-radius: 4px;
    font-family: $font-family;
    font-size: 14px;
    height: 38px;
    padding: 6px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;

    &:focus {
        border-color: $color-brand-red;
        box-shadow: 0 0 0 0.25rem rgba($color-brand-red, .25);
    }

    &::placeholder {
        color: $color-dark3;
    }

    &.parsley-error {
        border-color: $color-red;

        &:focus {
            border-color: $color-red;
            box-shadow: 0 0 0 0.25rem rgba($color-red, .25);
        }
    }
}

textarea {
    height: 100px;
    min-height: 100px;
    resize: vertical;
}

.Input {
    &-Group {
        display: flex;

        input {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: 0;
        }

        .Btn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            line-height: 38px;
        }
    }
}

.parsley-errors-list {
    li {
        color: $color-red;
    }
}
