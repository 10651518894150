.Loader {
    background: rgba(255, 255, 255, .82);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .1s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 999;

    &::before {
        background: url(../../../images/loader.svg);
        background-size: cover;
        content: '';
        display: block;
        left: calc(50% - 70px);
        height: 140px;
        position: relative;
        top: calc(50% - 70px);
        width: 140px;
    }

    &.Loader-Active {
        opacity: 1;
        visibility: visible;
    }
}
