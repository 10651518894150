.Btn {
    border: 0;
    border-radius: 4px;
    color: $color-brand-dark;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 34px;
    padding: 0 12px;
    text-align: center;
    touch-action: manipulation;
    transition: all .2s ease-in-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &.Loading {
        &::before {
            border-radius: 4px;
        }
    }

    &-Primary {
        background-color: $color-brand-red;
        color: white;

        &:hover {
            background-color: $color-red2;
        }

        &:active,
        &:focus {
            box-shadow: 0 0 0 0.25rem rgba($color-brand-red, .5);
        }
    }

    &-Secondary {
        background-color: $color-brand-light;

        &:hover {
            background-color: $color-dark1;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba($color-brand-light, .5);
        }
    }

    &-White {
        background-color: white;
        border: 1px solid $color-light1;

        &:hover {
            background-color: $color-light1;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba($color-light1, .5);
        }
    }
    
    &[disabled] {
        cursor: not-allowed;
        opacity: .65;
        pointer-events: none;
    }

    &-Group {
        display: flex;
        gap: 8px;
    }

    &-Hidden {
        display: none;
    }
}
