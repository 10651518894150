#Menu-Fade-Mask {
    display: none;
    opacity: 1;
    position: fixed;
    content: '';
    overflow: hidden;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0,0,0,0.9);
}

.HomePage {
    &-Categories {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: space-between;
        text-align: center;

        @include tablet {
            flex-direction: row;
        }
    }

    &-Category {
        position: relative;
        border-radius: 8px;
        margin-bottom: 30px;
        overflow: hidden;
        width: 100%;

        >* {
            background-color: $color-brand-light;
            color: white;
            display: block;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            position: relative;
            text-transform: uppercase;
            font-family: 'Montserrat-500';
            white-space: nowrap;

            @include tablet {
                height: 60px;
                line-height: 60px;
            }

            &:nth-child(even) {
                @include tablet {
                background-color: $color-dark2;
            }
            }

            &:hover {
                transition: all ease .3s;
            }
        }

        &Title {
            color: white;
            font-size: 20px;
            font-family: 'Montserrat-700';
        }

        &Small {
            >*:not(.HomePage-CategoryTitle) {
                @include tablet {
                    height: 80px;
                    line-height: 80px;
                }
            }
        }

        &__Orange {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;

            @include tablet {
                flex-direction: column;
            }

            a:not(.HomePage-CategoryTitle) {
                width: 50%;
                min-height: 100px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                border: 1px solid #f59c1a;

                @include tablet {
                    width: unset;
                    min-height: unset;
                    display: unset;
                    justify-content: unset;
                    align-items: unset;
                    border: unset;
                }
            }

            .HomePage-Category-Inner {
                display: block;
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: transparent !important;
            }            
                
                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    z-index: 1;
                    background: transparent !important;
                    -webkit-box-shadow: inset 0px 0px 5px 5px $color-brand-orange;
                    -moz-box-shadow: inset 0px 0px 5px 5px $color-brand-orange;
                    box-shadow: inset 0px 0px 5px 5px $color-brand-orange;
                }

            .HomePage-CategoryTitle {
                background: $color-brand-orange;
                width: 100%;
            }

            >*:not(.HomePage-CategoryTitle) {
                &:hover {
                    @include desktop {
                        background: $color-brand-orange;
                    }
                }
            }
        }

        &__Green {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;

            @include tablet {
                flex-direction: column;
            }

            a:not(.HomePage-CategoryTitle) {
                width: 50%;
                min-height: 100px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                border: 1px solid #90ca4b;

                @include tablet {
                    width: unset;
                    min-height: unset;
                    display: unset;
                    justify-content: unset;
                    align-items: unset;
                    border: unset;
                }

                &:nth-of-type(2) {
                    display: none;

                    @include tablet {
                        display: unset;
                    }
                }
            }

            .HomePage-Category-Inner {
                display: block;
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: transparent !important;
            }            
                
                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    z-index: 1;
                    background: transparent !important;
                    -webkit-box-shadow: inset 0px 0px 5px 5px $color-brand-green;
                    -moz-box-shadow: inset 0px 0px 5px 5px $color-brand-green;
                    box-shadow: inset 0px 0px 5px 5px $color-brand-green;
                }

            .HomePage-CategoryTitle {
                background: $color-brand-green;
                width: 100%;
            }

            >*:not(.HomePage-CategoryTitle) {
                &:hover {
                    @include desktop {
                        background: $color-brand-green;
                    }
                }
            }
        }

        &__Blue {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;

            @include tablet {
                flex-direction: column;
            }

            a:not(.HomePage-CategoryTitle) {
                width: 50%;
                min-height: 100px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                border: 1px solid #348fe2;

                @include tablet {
                    width: unset;
                    min-height: unset;
                    display: unset;
                    justify-content: unset;
                    align-items: unset;
                    border: unset;
                }

                &:last-of-type {
                    display: none;

                    @include tablet {
                        display: unset;
                    }
                }
            }

            .HomePage-Category-Inner {
                display: block;
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: transparent !important;
            }            
                
                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    z-index: 1;
                    background: transparent !important;
                    -webkit-box-shadow: inset 0px 0px 5px 5px $color-brand-blue;
                    -moz-box-shadow: inset 0px 0px 5px 5px $color-brand-blue;
                    box-shadow: inset 0px 0px 5px 5px $color-brand-blue;
                }        
            
            .HomePage-CategoryTitle {
                background: $color-brand-blue;
                width: 100%;;
            }

            >*:not(.HomePage-CategoryTitle) {
                &:hover {
                    @include desktop {
                        background: $color-brand-blue;
                    }
                }
            }
        }

        &__Icons:is(.HomePage-Category__Orange) {
            >* {
                &::after {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    content: '';
                    display: block;
                    pointer-events: none;
                    position: absolute;

                    @include tablet {
                        background-size: cover;
                    }
                }

                &:nth-of-type(2)::after {
                    background-image: url(../../../images/pilot_icons/isk.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 46px;
                        right: 10px;
                        left: unset;
                        top: 7px;
                        width: 45px;
                        transform: unset;
                    }
                }

                &:nth-of-type(3)::after {
                    background-image: url(../../../images/pilot_icons/plex.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 37px;
                        right: 10px;
                        left: unset;
                        top: 12px;
                        width: 50px;
                        transform: unset;
                    }
                }

                &:nth-of-type(4)::after {
                    background-image: url(../../../images/pilot_icons/injector.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 50px;
                        right: 29px;
                        left: unset;
                        top: 4px;
                        width: 15px;
                        transform: unset;
                    }
                }

                &:nth-of-type(5)::after {
                    background-image: url(../../../images/pilot_icons/emptyinjector.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 50px;
                        right: 29px;
                        left: unset;
                        top: 4px;
                        width: 15px;
                        transform: unset;
                    }
                }
            }
        }
        &__Icons:is(.HomePage-Category__Green) {
            >* {
                &::after {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    content: '';
                    display: block;
                    pointer-events: none;
                    position: absolute;

                    @include tablet {    
                        background-size: cover;
                    }
                }

                &:nth-of-type(2)::after {
                    background-image: unset;
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        background-image: url(../../../images/pilot_icons/pilot_buy.webp);
                        height: 50px;
                        right: 15px;
                        left: unset;
                        top: 15px;
                        width: 45px;
                        transform: unset;
                    }
                }

                &:nth-of-type(3)::after {
                    background-image: url(../../../images/pilot_icons/pilot_create.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 50px;
                        right: 15px;
                        left: unset;
                        top: 15px;
                        width: 45px;
                        transform: unset;
                    }
                }

                &:nth-of-type(4)::after {
                    
                    background-image: url(../../../images/pilot_icons/pilot_buy.webp);
                    height: 50px;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        background-image: unset;
                    }
                }
            }
        }
        &__Icons:is(.HomePage-Category__Blue) {
            >* {
                &::after {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    content: '';
                    display: block;
                    pointer-events: none;
                    position: absolute;

                    @include tablet {    
                        background-size: cover;
                    }
                }

                &:nth-of-type(2)::after {
                    background-image: url(../../../images/pilot_icons/ship.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 46px;
                        right: 15px;
                        left: unset;
                        top: 13px;
                        width: 45px;
                        transform: unset;
                    }
                }

                &:nth-of-type(3)::after {
                    background-image: url(../../../images/pilot_icons/structure.webp);
                    height: 50px;
                    right: unset;
                    left: 50%;
                    top: 25%;
                    transform: translate(-50%, -30%);
                    width: 50px;
                    @include tablet {
                        height: 47px;
                        right: 12px;
                        left: unset;
                        top: 12px;
                        width: 50px;
                        transform: unset;
                    }
                }
            }
        }
    }

    &-Perks {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        text-align: center;

        h2 {
            flex: 100%;
            margin-top: 40px;
            text-align: center;
            font-family: "Montserrat-900";
            text-transform: uppercase;
        }
    }

    &-Perk {
        @include tablet {
            flex: 1 1 calc(50% - 8px);
        }

        @include desktop {
            flex: 1 1 calc(33% - 8px);
        }

        &Icon {
            display: block;
            filter: invert(55%) sepia(42%) saturate(4834%) hue-rotate(327deg) brightness(103%) contrast(112%);
            height: 70px;
            margin: 30px auto;
            transform: translateY(2px);
            width: 70px;
        }

        h4 {
            font-weight: bold;
            text-align: center;
            display: inline-block;
            font-family: 'Montserrat-700';
            text-transform: uppercase;
            padding-bottom: 4px;
            border-bottom: 3px solid #ff5b57;
            font-size: 16px;
        }
    }

    &-HelloTextWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px 8px;
        background: 	rgba(29,34,38,0.8);
        border-radius: 6px;
        border: 2px solid #ff5b57;
    }


    &-Slider {
        &Section {
            background: $color-dark2;
            height: auto;
            margin-bottom: 50px;
            position: relative;
            border-bottom: 2px solid #ff5b57;

            @include tablet {
                min-height: 240px;
            }

            @include desktop {
                min-height: 300px;
            }
        }

        &Sidebar {
            color: white;
            height: 210px;
            overflow: hidden;
            padding: 20px 10px 10px 10px;
            position: relative;
            width: 100%;
            z-index: 1;

            @include tablet {
                height: 100%;
                left: calc(50% - 360px);
                margin: 0 auto;
                min-width: 300px;
                padding: 20px 30px 20px 0;
                position: absolute;            
                width: auto;
            }

            @include desktop {
                left: calc(50% - 485px);
            }

            @include desktop-big {
                left: calc(50% - 570px);
            }

            h2 {
                color: #fff;
                font-family: 'Montserrat-900';
                text-transform: uppercase;
                border-bottom: 2px solid #ff5b57;
                margin: 0 0 4px 0;
                text-align: center;
                line-height: 1.2;
                font-size: 28px;
                @include desktop {
                    line-height: 1.6;
                    font-size: 30px;
                }
            }

            p {
                font-family: 'Play-Regular';
                text-align: center;
                margin: 0 !important;                
            }
        }

        &Tooltip {
            background: white;
            display: inline-block;
            height: 12px;
            margin-left: 8px;
            mask-size: contain;
            mask-image: url(../../../images/icons/question.svg);
            transform: translateY(2px);
            width: 12px;
        }

        &Status {
            bottom: 20px;
            display: flex;
            flex-direction: row;
            gap: 8px;
            position: absolute;
            width: 100%;
            left: 0;
            justify-content: space-around;

            @include tablet {
                flex-direction: column;
                gap: 3px;
                width: 74%;
            }

            strong {
                text-transform: uppercase;
            }

            span {
                &::before {
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    height: 8px;
                    margin: 0 5px;
                    vertical-align: middle;
                    width: 8px;
                }
            }

            i {
                cursor: pointer;
                margin-left: 5px;
            }

            &__Green::before {
                background-color: $color-brand-green;
            }

            &__Orange::before {
                background-color: $color-brand-orange;
            }

            &__Red::before {
                background-color: $color-brand-red;
            }
        }

        &Item {
            display: none;
            position: relative;

            &::after {
                content: '';
                background-image: url($pattern-grey);
                background-position: center;
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -1;
            }

            &.active {
                display: block;
            }

            &__Text {
                color: white;
                text-align: center;
                z-index: 1;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
                padding: 5px 15px;
                background: rgba(29, 34, 38, 0.8);
                border-radius: 6px;

                h3 {
                    font-family: 'Montserrat-900';
                    font-size: 16px;
                    margin: 0;
                    @include tablet {
                        font-size: 26px;
                        margin-bottom: 10px;
                        margin-top: 5px;
                    }
                }

                p {
                    font-family: 'Montserrat-500';
                    font-size: 12px;
                    margin: 0;
                    @include tablet {
                        font-size: 16px;
                        margin-bottom: 12px;
                    }
                }

                @include tablet {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin: 0 auto;
                    max-width: 720px;
                    text-align: right;
                    padding: 0;
                    background: unset;
                    top: 5%;
                    right: 5%;
                    transform: unset;
                }

                @include desktop {
                    max-width: 940px;
                }

                @include desktop-big {
                    max-width: 1140px;
                }

                a {
                    text-decoration: underline;
                }
            }
        }

        .slick {
            &-initialized {
                .HomePage-SliderItem {
                    display: inline-block;
                }
            }

            &-slide {
                display: block;
            }

            &-dots {
                bottom: 20px;
                right: 30px;
                position: absolute;
                width: auto;

                @include tablet {
                    left: 50%;
                    max-width: 720px;
                    text-align: right;
                    transform: translateX(-50%);
                    width: 100%;
                }

                @include desktop {
                    max-width: 940px;
                }

                @include desktop-big() {
                    max-width: 1140px;
                }

                li {
                    height: 6px;
                    width: 30px;

                    @include tablet {
                        width: 40px;
                   }

                    button {
                        background: $color-brand-light;
                        border-radius: 4px;
                        height: 30px;
                        padding: 0;
                        width: 30px;

                        @include tablet {
                             width: 40px;
                        }

                        &::before {
                            content: none;
                        }
                    }

                    &.slick-active {
                        button {
                            background: $color-brand-red;
                        }
                    }
                }
            }
        }
    }

    &-Reviews {
        margin-top: 25px;

        h2 {
            margin-top: 40px;
            margin-bottom: 20px;
            text-align: center;
            font-family: "Montserrat-900";
            text-transform: uppercase;
        }

        .slick-slide {
            margin: 0 8px;

            > div {
                height: 100%;
            }
        }

        .slick-list {
            margin: 0 -8px;
        }
    }

    &-Review {
        background: $color-light2;
        border-radius: 8px;
        height: 100%;
        padding: 10px 15px;
        position: relative;

        &::before {
            background: $color-brand-red;
            bottom: 10px;
            content: '';
            display: block;
            height: 60px;
            mask-size: contain;
            mask-image: url(../../../images/icons/quote.svg);
            opacity: .2;
            position: absolute;
            right: 15px;
            width: 60px;
        
        }

        &__Stars {
            background: url(../../../images/icons/5stars.svg) no-repeat center;
            background-size: cover;
            height: 14px;
            width: 82px;
        }

        &__Header {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        &__Author {
            font-size: 16px;
            font-weight: 700;
        }

        &__Country {
            color: lighten($color-brand-dark, 50%);
            font-size: 11px;
        }

        &__Text {
            padding-right: 75px;
            padding-top: 20px;
        }
    }
}
