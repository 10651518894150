.Breadcrumbs {
    border-bottom: 1px solid $color-light2;
    font-size: 12px;
    margin: 0 auto 20px;
    text-transform: uppercase;

    @include tablet {
        font-size: 15px;
    }

    .Container {
        align-items: center;
        display: flex;
        height: 35px;
        justify-content: space-between;        
    }
}

.Breadcrumb {
    // color: $color-light3;
    color: black;
    font-family: 'Montserrat-700';

    &:focus,
    &:hover {
        color: $color-brand-red;
        fill: $color-brand-red;
    }

    + a::before {
        color: #ccc;
        content: '/ ';
        padding: 0 5px;
    }

    &-Title {
        color: $color-brand-red;
        display: none;
        font-weight: 600;
        margin: 0;
        text-transform: uppercase;
        font-family: 'Montserrat-700';

        @include tablet {
            display: block;
        }
    }
}
