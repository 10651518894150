* {
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

html {
    height: 100%;
}

body {
    background: $color-brand-dark;
    color: $color-brand-dark;
    font-family: $font-family;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    height: 100%;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    touch-action: manipulation;
    width: 100%;
}

a {
    color: $color-brand-red;
    text-decoration: none;
    transition: color .2s;

    &:visited,
    &:focus,
    &:hover,
    &:active {
        text-decoration: none;
    }

}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 5px;
}

h1 {
    font-size: 36px
}

h2 {
    font-size: 30px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 18px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 12px
}

small {
    color: $color-dark3;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;

    thead {
        th {
            //border-bottom: 2px solid $c-gray2;
            font-weight: bold;
            vertical-align: middle;
        }
    }

    th,
    td {
        //border-top: 1px solid $c-gray2;
        padding: 6px 10px;
        vertical-align: middle;
    }
}

hr {
    border: 1px solid $color-light2;
    margin: 10px 0;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p {
    margin-top: 0;
}

main {
    background: white;
    margin: $header-height auto 0;
    max-width: 100vw;
    overflow: hidden;
    padding-bottom: 40px;

    @include desktop() {
        margin-top: $header-height-desktop;
    }
}

.Container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    @include tablet {
        padding: 0;
        width: $container-width-tablet;
    }

    @include desktop {
        width: $container-width-desktop;
    }

    @include desktop-big {
        width: $container-width-desktop-big;
    }
}
