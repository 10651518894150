.FormGroup {
    margin-bottom: 10px;
    width: 100%;
}

.Label {
    &-Required {
        color: $color-brand-red;
    }
}

label {
    color: $color-light3;
    display: block;
    margin-bottom: 4px;
}
