table {
    > thead {
        > tr {
            > th {
                border-bottom: 2px solid $color-light4;
                vertical-align: bottom
            }
        }
    }
}
