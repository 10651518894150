#braintree-form {
	.parsley-errors-list {
		display: none;
	}

	.form-control.parsley-error ~ .parsley-errors-list {
		display: block;
	}
}

.braintree-hosted-fields-focused {
	border-color: $color-brand-red;
	box-shadow: inset 0 1px 1px black, 0 0 8px $color-brand-red;
}

#paypal-button {
	cursor: pointer;
	margin: 0 auto;
	width: 150px;

	&.disabled > div {
		pointer-events: none;
	}
}
