.Pagination {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    &-Items {
        display: flex;
        margin-left: 1px;

        >* {
            border: 1px solid $color-light4;
            color: $color-brand-red;
            display: block;
            margin-left: -1px;
            padding: 6px 12px;
            position: relative;
            transition: all .15s ease-in-out;

            &:hover {
                color: darken($color-brand-red, 30%);
                background-color: lighten($color-light4, 5%);
            }

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &[aria-disabled=true] {
                pointer-events: none;
            }
        }
    }

    &-Current {
        background: $color-brand-red;
        border-color: $color-brand-red;
        color: #ffffff;
        z-index: 2;

        &:hover {
            background: $color-brand-red;
            border-color: $color-brand-red;
            color: #ffffff;
        }
    }
}
