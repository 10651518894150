$bp-mobile: 320px;
$bp-mobile-medium: 480px;
$bp-mobile-big: 640px;
$bp-tablet: 768px;
$bp-desktop: 992px;
$bp-desktop-big: 1140px;
$bp-desktop-big: 1440px;

@mixin media($breakpoint, $direction) {
    @media only screen and ($direction: $breakpoint) {
        @content;
    }
}

@mixin mobile-medium() {
    @include media($bp-mobile-medium, min-width) {
        @content;
    }
}

@mixin mobile-big() {
    @include media($bp-mobile-big, min-width) {
        @content;
    }
}

@mixin mobile-big-down() {
    @include media($bp-tablet - 1, max-width) {
        @content;
    }
}

@mixin tablet() {
    @include media($bp-tablet, min-width) {
        @content;
    }
}

@mixin tablet-down() {
    @include media($bp-desktop - 1, max-width) {
        @content;
    }
}

@mixin desktop() {
    @include media($bp-desktop, min-width) {
        @content;
    }
}

@mixin desktop-big() {
    @include media($bp-desktop-big, min-width) {
        @content;
    }
}

@mixin desktop-bigger() {
    @include media($bp-desktop-bigger, min-width) {
        @content;
    }
}
