.Loading {
    position: relative;

    &::before {
        background: white;
        content: '';
        height: 100%;
        left: 0;
        opacity: .6;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
    }

    &::after {
        background: url('../../images/loader.svg') no-repeat center;
        background-size: cover;
        content: '';
        display: block;
        height: 26px;
        left: calc(50% - 30px);
        position: absolute;
        top: calc(50% - 13px);
        width: 60px;
        z-index: 11;
    }
}

.trustpilot-widget {
    max-height: 100px;
}
