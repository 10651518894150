.animated {
    animation-duration: 1s;
    animation-fill-mode: both;

    &.hinge {
        animation-duration: 2s;
    }

    &.flip {
        //Instead of the line below you could use @include backface-visibility($visibility)
        backface-visibility: visible;
        animation-name: flip;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(0);
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateX(0);
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateY(0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}
