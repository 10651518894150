select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 2px solid $color-light1;
    border-radius: 4px;
    font-family: $font-family;
    font-size: 14px;
    height: 38px;
    padding: 6px 36px 6px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;

    &:focus {
        border-color: $color-brand-red;
        box-shadow: 0 0 0 0.25rem rgba($color-brand-red, .25);
    }

    &::placeholder {
        color: $color-dark3;
    }
}
