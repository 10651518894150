.Notification {
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 8px 15px;

    a {
        font-weight: 700
    }

    &-Success {
        background-color: rgba($color-brand-green, .2);
        border-color: rgba($color-brand-green, .3);
        color: $color-brand-green;
    }

    &-Error {
        background-color: rgba($color-brand-red, .2);
        border-color: rgba($color-brand-red, .3);
        color: $color-brand-red;
    }
}
