.ErrorPage {
    &-Container {
        padding: 50px 0 10px;
        text-align: center;

        h1 {
            font-size: 50px;

            @include tablet {
                font-size: 80px;
            }
        }
    }
}
