.irs--round {
    .irs-min,
    .irs-max {
        background: $color-brand-light;
        color: white;
        padding: 5px 10px;
    }

    .irs-bar {
        background-color: $color-brand-red;
    }

    .irs-handle {
        border: 4px solid $color-brand-red;
    }

    .irs-from,
    .irs-to,
    .irs-single {
        background-color: $color-brand-red;
        padding: 5px 10px;

        &::before {
            border-top-color: $color-brand-red;
        }
    }
}
