.Header {
    background-color: $color-brand-dark;
    border-bottom: 2px solid $color-brand-red;
    color: white;
    left: 0;
    min-height: $header-height;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1030;

    @include desktop {
        height: $header-height-desktop;
    }

    > .Container {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: $header-height;

        @include desktop {
            align-items: initial;
            flex-direction: column;
            height: 100%;
        }
    }

    &-TopWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 50%;

        @include desktop {        
        flex-direction: row;
        width: 100%;
        }
    }

    &-Logo {
        max-width: 110px;

        @include desktop {
            margin: 10px auto 0 0;
            transform: translateY(-3px);
        }

        img {
            height: 36px;
            width: auto;
        }
    }

    &-TopMenu {
        display: flex;
        gap: 20px;
        margin-left: auto;

        &Container {
            display: flex;
            padding-top: 5px;
        }

        a {
            color: #fff;
        }
    }

    &-MenuContainer {
        align-items: flex-end;
        justify-content: normal;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;

        @include desktop {
            align-items: flex-end;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &-Menu {
        background: $color-brand-dark;
        border-bottom: 2px solid $color-brand-red;
        display: flex;
        flex-direction: column;
        right: 0;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding: 0 15px;
        position: fixed;
        text-transform: uppercase;
        top: $header-height;
        width: 50%;
        font-family: "Play-Bold";

        &-Top {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            opacity: 0;
            height: 0;
            left: 0;
            top: $header-height;
            overflow: hidden;
            width: 50%;
            position: fixed;

            @include desktop {
                opacity: 1;
                position: initial;    
                flex-direction: row;
                width: auto;
                height: unset;

                .Header-Menu-Top-Item-WOW {
                    display: none;
                }
            }         

            &-Item:not(.Header-Menu-Top-Item-WOW) {
                padding: 6px 7px;
                color: white;
                font-family: "Play-Regular";
                text-transform: lowercase;
                text-align: center;
                text-decoration: underline;
                text-underline-offset: 5px;

                &:hover {
                    color: orange;                   
                }
            }
            
            &__Open {
                height: 260px;
                opacity: 1;
                background: #1d2226;
                border-right: 1px solid #ff5b57;
                border-bottom: 2px solid #ff5b57;
                padding: 0 7px;

                .Header-Menu-Top-Item-WOW {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px 3px 0 0;
                    margin: 5px auto 0 auto;
                    padding: 5px 10px;
                    width: 100%;
                    background: #ff5b57;
                    color: #fff;
                    font-family: "Play-Bold";
                    text-decoration: none;
                    text-underline-offset: 0;
                    white-space: nowrap;

                    &:hover {                       
                        background: orange;
                        color: black;
                        transition: background 0.2s ease-in-out;                        
                    }
                }
    
                @include desktop {
                    border-top: 2px solid $color-dark1;
                }
            }
        }

        @include desktop {
            border-bottom: 0;
            flex-direction: row;
            margin-top: 0;
            max-height: unset;
            opacity: 1;
            padding: 0;
            position: initial;
            width: auto;
        }

        &__Open {
            max-height: 260px;
            opacity: 1;
            border-left: 1px solid #ff5b57;
            padding: 0 7px;

            @include desktop {
                border-top: 2px solid $color-dark1;

                .Header-Menu__Item_WOW_main {
                 display: none;
                }
            }
        }

        &__Item {
            border-radius: 3px 3px 0 0;
            margin: 0 auto;
            padding: 5px 10px;
            width: 100%;

            // &_WOW_main {
            //     margin-left: 10px;
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: flex-start;
            //     align-items: center;
            //     white-space: nowrap;
            //     width: 44px;
            //     max-width: 44px;
            //     overflow: hidden;

            //     &:hover {                    
            //     width: unset;
            //     max-width: 100px;                   
            //     }
            // }

            @include tablet {
                max-width: $container-width-tablet;
            }

            @include desktop {
                max-width: unset;
                width: auto;
            }

            &:hover {
                @include desktop {
                    background: $color-brand-red;
                    color: #fff;
                    transition: background 0.2s ease-in-out;
                }
            }

            &.Orange {
                color: $color-brand-orange;

                &:hover {                   
                    background: $color-brand-red;
                    color: #fff;
                    transition: background 0.2s ease-in-out;                        
                }
            }

            &.Green {
                color: $color-brand-green;

                &:hover {                   
                    background: $color-brand-red;
                    color: #fff;
                    transition: background 0.2s ease-in-out;                        
                }
            }

            &.Blue {
                color: $color-brand-blue;

                &:hover {                   
                    background: $color-brand-red;
                    color: #fff;
                    transition: background 0.2s ease-in-out;                        
                }
            }

            &Highlight {
                background: $color-brand-red;
                color: #fff;
                fill: #fff;
                white-space: nowrap;

                &:hover {                   
                        background: orange;
                        color: black;
                        fill: black;
                        transition: background 0.2s ease-in-out;                        
                    }
                }
            }
        }

        &-Minicart {
            border-radius: 3px 3px 0 0;
            padding: 8px 14px;
            position: relative;

            &:hover {
                @include desktop {
                    background: $color-brand-red;
                    transition: background 0.2s ease-in-out;
                }
            }

            &::before {
                background: url("../../../images/icons/shopping-cart.svg") no-repeat 50%;
                background-size: contain;
                content: "";
                display: block;
                height: 20px;
                width: 20px;

                @include desktop {
                    height: 16px;
                    width: 16px;
                }
            }

            &__Count {
                background-color: $color-brand-red;
                border-radius: 50%;
                display: block;
                height: 10px;
                line-height: 18px;
                overflow: hidden;
                position: absolute;
                right: 11px;
                top: 4px;
                width: 10px;

                @include desktop {
                    right: 6px;
                    top: 7px;
                }
            }
        }

        &-Icons {
            align-items: center;
            display: flex;
            transform: translateY(-6px);

            @include desktop {
                transform: none;
            }
        }

        &-Hamburger {
            cursor: pointer;
            padding: 8px 14px;

            @include desktop {
                display: none;
            }

            &Bar {
                background: #fff;
                border-radius: 1px;
                display: block;
                height: 2px;
                transition: all .2s; transform-origin: 10% 10%;
                width: 22px;

                &:nth-of-type(2),
                &:nth-of-type(3) {
                    margin-top: 5px;
                }
            }

            &__Open {
                .Header-HamburgerBar {
                    &:nth-of-type(1) {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }

                    &:nth-of-type(2) {
                        opacity: 0;
                    }

                    &:nth-of-type(3) {
                        margin-top: 3px;
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                    }
                }
            }
        }

        &-Checkout {
            .Container {
                justify-content: center;
            }

            .Header {
                &-Logo {
                    margin: 0 auto;

                    @include desktop {
                        transform: translateY(3px);
                    }
                }
            }
        }
    }
