@import "abstract/media";
@import "abstract/variables";
@import "abstract/animations";

@import "base/layout";
@import "base/common";

@import "~ion-rangeslider/css/ion.rangeSlider.css";
// $fa-font-path: "../../fonts/play";
// @import 'font-awesome/scss/font-awesome';


@import "components/accordion";
@import "components/button";
@import "components/button";
@import "components/forms";
@import "components/header";
@import "components/slick";
@import "components/footer";
@import "components/loader";
@import "components/table";
@import "components/input";
@import "components/select";
@import "components/range";
@import "components/tooltip";
@import "components/notification";
@import "components/checkbox";
@import "components/breadcrumbs";
@import "components/pagination";
@import "components/catalog/category";
@import "components/catalog/product";
@import "components/catalog/product-block";
@import "components/checkout/checkout";
@import "components/checkout/braintree";

// Pages
@import "components/pages/homepage";
@import "components/pages/faq";
@import "components/pages/for-suppliers";
@import "components/pages/contact-us";
@import "components/pages/error-page";
@import "components/pages/about-us";

// // Plugins
// @import "components/vendor/noty/styles";
