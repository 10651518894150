.Accordion {
    &-Item {
        background: rgba($color-light1, 1);
        border-radius: 4px;
        margin-bottom: 15px;
        padding: 10px;

        &:not(.Accordion-Active) {
            background: rgba($color-light1, .6);
            cursor: pointer;
        }
    }

    &-Title {
        position: relative;
        font-family: 'Montserrat-900';

        &::before {
            background: $color-light2;
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 16px;
            margin-right: 10px;
            transform: translateY(2px);
            width: 16px;
        }

        small {
            font-family: "Play-Regular";
        }
    }

    &-Content {
        display: none;
        padding: 15px 27px;
        position: relative;
    }

    &-Active {
        .Accordion-Title {
            &::after {
                background: $color-brand-red;
                border-radius: 50%;
                content: '';
                height: 10px;
                left: 3px;
                position: absolute;
                top: 5px;
                width: 10px;
            }
        }

        .Accordion-Content {
            display: block;
        }
    }
}
