.ContactUsPage {
    &-Container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tablet {
            flex-direction: row;
        }

        > div {
            width: 100%;

            &:nth-of-type(1) {
                text-align: center;
            }
        }
        &-LInner {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;

            h2 {
                font-family: "Montserrat-900";
                text-transform: uppercase;
                text-align: center;                
                font-size: 24px;

                @include tablet {
                    font-size: 30px;
                }
            }

            h3 {
                font-family: "Montserrat-700";
                text-transform: uppercase;
                text-align: center;
                margin: 0;
            }

            &-Time {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                @include tablet {
                    flex-direction: row;
                    justify-content: center;
                }

                div {
                    width: 60px;
                    height: 60px;                    
                    margin: 0 0 10px 0;

                    @include tablet {
                        margin: 0 10px 0 0;
                    }
                }

                h2 {
                    font-family: "Montserrat-900";
                    text-transform: uppercase;
                    text-align: center;                    
                    font-size: 24px;
                    margin: 0;
    
                    @include tablet {
                        font-size: 30px;
                    }
                }
            }            
        }

        &-RInner {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h2 {
                font-family: "Montserrat-900";
                text-transform: uppercase;                
                font-size: 24px;
                text-align: center;
                @include tablet {
                    font-size: 30px;
                    text-align: left;
                }
            }
        }
    }

    &-Captcha {
        margin-bottom: 10px;
        transform: scale(0.8) translateX(-12.3%);

        @include tablet {
            transform: none;   
        }
    }
}

.ContactsBlock {
    list-style-type: none;
    padding: 0;

    a {
        color: $color-brand-dark;
    }

    li {
        margin-bottom: 15px;

        div {
            background: $color-brand-red;
            display: inline-block;
            height: 20px;
            margin-right: 10px;
            mask-size: contain;
            transform: translateY(5px);
            width: 20px;
        }
    }

    &-Envelope {
        mask-image: url('../../../images/icons/envelope.svg');
    }

    &-Discord {
        mask-image: url('../../../images/icons/discord.svg');
    }

    &-Skype {
        mask-image: url('../../../images/icons/skype.svg');
    }

    &-Webchat {
        mask-image: url('../../../images/icons/webchat.svg');
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
    }
}
